import consumer from "./consumer"

consumer.subscriptions.create({
  channel: 'TemplateInfoChannel'
}, {
  connected() {
    // Called when the subscription is established
    console.log('Connected to TemplateInfoChannel');
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log('Disconnected from TemplateInfoChannel');
  },

  received(data) {
    // Called when data is broadcasted to the channel
    console.log('Received data:', data);
    // Handle the received data as needed
    
      var template_info_id = $("#template_info_id").val();

      if (template_info_id != "" && data["template_info_id"] == template_info_id)
      {
        if (data["status"] == "in_progress"){
          $("#loading-bar").show();
          $("#loading-bar-percentage").css("width", data["percentage"] + "%");
        }
        else if (data["status"] == "completed"){


          $("#loading-bar").hide();
          $("#loading-bar-percentage").css("width", "0%");
          if (data["tool"] == "google"){
            $("#template_name").val(data["template_attributes"]["Template Name"]);
            var need_to_add = data["template_attributes"]["Google Ad Headlines"].length - 5
            for(let i = 0; i < need_to_add; i++){
              if ($(".headline").length < 15)
                $(".add-headline")[0].click();
            }
            var headlines = data.template_attributes["Google Ad Headlines"];
            $(".headline").each(function (index) {
              var inputToSet = $(this).find('input');
              inputToSet.val(headlines[index]);
            });
            need_to_add = data["template_attributes"]["Long Headlines"].length - 2
            for(let i = 0; i < need_to_add; i++){
              if ($(".long-headline").length < 5)
              $(".add-long-headline")[0].click()
            }
            headlines = data.template_attributes["Long Headlines"];
            $(".long-headline").each(function (index) {
              var inputToSet = $(this).find('input');
              inputToSet.val(headlines[index]);
            });
            var category_id = data["template_attributes"]["category_id"];
            if (category_id != undefined){
              $("#template_category_id").val(category_id).trigger("change.select2");
              var selectedText = $("#template_category_id option:selected").text();
              if (selectedText != undefined)
                $("#select2-template_category_id-container").text(selectedText);
            }
            $(".short-description").val(data.template_attributes["Short Description"]);
            $($(".long-description")[0]).val(data.template_attributes["Descriptions"][0]);
            $($(".long-description")[1]).val(data.template_attributes["Descriptions"][1]);
            $("#template_targeting_google_audience_signal_keywords").val(data.template_attributes["Keywords"]);
            var affinity_audience = data.template_attributes["Affinity Audience"].split(',').map(function(item) {
                return item.trim();
            });
            affinity_audience.forEach(function(item){
              var matchingOptions = $(".select-user-interests-affinity option:contains('" + item + "')").filter(function() {
                return $(this).text() === item;
              });
              if (matchingOptions.length > 0){
                $(`.user-interests-affinity-mock-button`).attr("data-id", matchingOptions.val());
                $(`.user-interests-affinity-mock-button`).attr("data-name", matchingOptions.text());
                $(`.user-interests-affinity-mock-button`).click();
              }
            });

            var market_audience = data.template_attributes["In Market Audience"].split(',').map(function(item) {
              return item.trim();
            });
            market_audience.forEach(function(item){
              var matchingOptions = $(".select-user-interests-in-market option:contains('" + item + "')").filter(function() {
                return $(this).text() === item;
              });
              if (matchingOptions.length > 0){
                $(`.user-interests-in-market-mock-button`).attr("data-id", matchingOptions.val());
                $(`.user-interests-in-market-mock-button`).attr("data-name", matchingOptions.text());
                $(`.user-interests-in-market-mock-button`).click();
              }
            });
          }
          else{
            if (data["campaign_pid"]){
              var form = $('.edit_template, .new_template');
              var campaignpidInput = $('<input>', {
                type: 'text',
                name: 'template[meta][campaign_pid]',
                value: data["campaign_pid"]
              }).addClass("hidden")
              form.append(campaignpidInput);
            }
            $("#template_name").val(data["template_attributes"]["name"]);
            $("#template_body").val(data["template_attributes"]["body"]);
            $("#template_headline").val(data["template_attributes"]["headline"]);
            $("#template_link_description").val(data["template_attributes"]["link_description"]);
            $("#template_welcome_message").val(data["template_attributes"]["welcome_message"]);
            $("#template_greeting_title").val(data["template_attributes"]["greeting_title"]);
            $("#template_greeting_text").val(data["template_attributes"]["greeting_text"]);
            $("#template_question_description").val(data["template_attributes"]["question_description"]);
            $("#template_completion_title").val(data["template_attributes"]["completion_title"]);
            $("#template_completion_description").val(data["template_attributes"]["completion_description"]);
            $("#template_completion_call_to_action_text").val(data["template_attributes"]["completion_call_to_action_text"]);
            var category_id = data["template_attributes"]["category_id"];
            if (category_id != undefined){
              $("#template_category_id").val(category_id).trigger("change.select2");
              var selectedText = $("#template_category_id option:selected").text();
              if (selectedText != undefined)
                $("#select2-template_category_id-container").text(selectedText);
            }
            if (data["template_attributes"]["gender"] != undefined){
              $("#template_targeting_gender_"+data["template_attributes"]["gender"].toLocaleLowerCase()).click()
            }
            if (data["template_attributes"]["age_min"] != undefined){
              $("#template_targeting_age_min").val(data["template_attributes"]["age_min"]).change()
            }
            if (data["template_attributes"]["age_max"] != undefined){
              $("#template_targeting_age_max").val(data["template_attributes"]["age_max"]).change()
            }
            if (data["template_attributes"]["language_code"] != undefined){
              $("#template_targeting_language_code").val(data["template_attributes"]["language_code"]).change()
            }
            if (data["template_attributes"]["default_radius"] != undefined){
              $("#template_targeting_default_radius").val(data["template_attributes"]["default_radius"]).change()
            }
          }
        }
        else{
          $("#loading-bar").show();
          $(".loading-template-text").removeClass("text-gray-800 dark:text-white");
          $(".loading-template-text").addClass("text-red-500");
          $(".loading-template-text").text("Error loading template");
        }
      }
      
      if ($("#template_info_page").val() == "true"){
        button_link = `/templates/new?template_info_id=${data["template_info_id"]}&amp;show_generated=true`;
        template_name = data["template_attribtues"]["name"];
        if (data["tool"] == "google"){
          button_link += "&platform=google";
          template_name = data["template_attributes"]["Template Name"];
        }
        if ($("#previous_template_requests").length != 0){
          var html = `
            <div class="grid grid-cols-4 gap-2 px-4 py-3 text-gray-700 dark:text-gray-300 text-sm">
              <div class="col-span-1 pt-4">${template_name}</div>
              <div class="col-span-1 pt-4">${data["tool_text"]}</div>
              <div class="col-span-1 pt-4">${data["created_at"]}</div>
              <div class="col-span-1">
                <div class="flex flex-row justify-between">
                  <a class="cursor-pointer next-step sm:w-72 flex justify-center items-center py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 mt-2" target="_blank" href="${button_link}">
                    <span> See Template </span>
                  </a>        
                </div>
              </div>
            </div>
          `
          $("#previous_template_requests").prepend(html);
        }
        else{
          var html = `
            <div class="bg-gray-100 dark:bg-gray-800 px-4 py-2 border-b border-gray-300 dark:border-gray-700">
              <div class="grid grid-cols-4 gap-2 text-gray-600 dark:text-gray-400 text-sm">
                <div class="col-span-1">Name</div>
                <div class="col-span-1">Tool</div>
                <div class="col-span-1">Created At</div>
                <div class="col-span-1">Action</div>
              </div>
            </div>
            <div class="bg-white dark:bg-gray-800">
              <div id="previous_template_requests">
                <div class="grid grid-cols-4 gap-2 px-4 py-3 text-gray-700 dark:text-gray-300 text-sm">
                  <div class="col-span-1 pt-4">${template_name}</div>
                  <div class="col-span-1 pt-4">${data["tool_text"]}</div>
                  <div class="col-span-1 pt-4">${data["created_at"]}</div>
                  <div class="col-span-1">
                    <div class="flex flex-row justify-between">
                      <a class="cursor-pointer next-step sm:w-72 flex justify-center items-center py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 mt-2" target="_blank" href="${button_link}">
                        <span> See Template </span>
                      </a>        
                    </div>
                  </div>
                </div>
              </div>
            </div>
          `
          $("#previous_template_requests_outer_container").append(html);
        }
      }    
    
  }
});
